import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';

import { Label, SearchBar, StatusLabel, SubstatusLabel, UserAvatar } from 'components';
import _ from 'lodash';
import { ParamsPagination, useFiltersSearch, usePagination } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import { LEASE_PROVIDERS_TYPE, DEFAULT_LEASE_PROVIDER } from 'common';
import { Search } from '../../../../components/SearchBar/components';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 198px)',
    overflow: 'auto'
  },
  substatuses: {
    width: 220,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  editorContainer: {
    border: '1px solid rgba(219, 208, 175, 0.5)',
    borderRadius: 8,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
  },
  history_avatar: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1)
  },
  center: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  flexGrow: {
    flexGrow: 1
  },
  tableRow: {
    maxHeight: 40
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  padding5: {
    padding: '5px',
  }
}));


const maxDate = products_data => {
  const max_date = _.max(products_data?.map(item => {
    return item.available_at;
  }) || []);

  if (max_date) {
    return moment(max_date.split('T')[0]).format(
      'DD MMM YYYY'
    );
  }
  return '-';
};

const costTotal = products_data => {
  return products_data?.reduce((sum, item) => {
    return sum + (parseFloat(item.base_cost_price) || 0.0) * (item.quantity || 1);
  }, 0.0) || 0.0;
};

const cancelledSubStatusMasquerade = {
  'preliminary': 'Preliminary',
  'final_id_verification': 'Final ID Verification',
  'final_customer_request': 'Final By Customer Request',
  'lease_terminated': 'Lease Terminated'
};

const getSubStatusLabel = (subStatus) => {
  switch (subStatus) {
    case 'Canceled':
      return 'Canceled';
    case 'Returned':
      return 'Returned';
    case 'BB not canceled':
      return 'BB not canceled';
    case 'Price change':
      return 'Price change';
    case 'Out of stock':
      return 'Out of stock';
    case 'Delivery address updated':
      return 'Delivery address updated';
    case 'Duplicate':
      return 'Duplicate';
    case 'Lease':
      return 'Lease Provider Error';
    case 'Reprocess':
      return 'Reprocess';
    case 'Shipping':
      return 'Available';
    case 'Approve Required':
      return 'Approval Required';
    case 'Waiting For Customer ID':
      return 'Waiting For Customer ID';
    case 'Verified':
      return 'Auto Approval';
    case 'Manual Approval':
      return 'Manual Approval';
    case 'Verified Unexpected':
      return 'Suspected Fraud';
    case 'Suspicious IP Location':
      return 'Suspicious IP Location';
    case 'Jumio Success':
      return 'Jumio Success';
    case 'Jumio Failed':
      return 'Jumio Failed';
    case 'Veriff Success':
      return 'Veriff Success';
    case 'Veriff Failed':
      return 'Veriff Failed';
    case 'Veriff Risks':
      return 'Veriff Risks';
    case 'Elderly Person':
      return 'Elderly Person';
    case 'Blacklist':
      return 'Blacklist';
    case 'Waiting For SMS Delivery':
      return 'Waiting For SMS Delivery';
    case 'Added Lease':
      return 'Added Lease';
    default:
      return subStatus;
  }
};

const getStatusLabel = (status) => {
  switch (status) {
    case 'Pending':
      return 'Pending';
    case 'Placed With BestBuy':
      return 'Placed With BestBuy';
    case 'Synced Placed With BestBuy':
      return 'Placed With BestBuy';
    case 'Placed With Giga':
    case 'Synced Placed With Giga':
      return 'Placed With Giga';
    case 'Being Processed':
    case 'Synced Being Processed':
      return 'Being Processed';
    case 'Ready For Pickup':
      return 'Ready For Pickup';
    case 'Synced Ready For Pickup':
      return 'Ready For Pickup';
    case 'Fulfillment Confirmed':
      return 'Fulfillment Confirmed';
    case 'Synced Fulfillment Confirmed':
      return 'Fulfillment Confirmed';
    case 'Lease Initiated':
      return 'Lease Initiated';
    case 'Synced Lease Initiated':
      return 'Lease Initiated';
    case 'Lease Confirmed':
      return 'Lease Confirmed';
    case 'Synced Lease Confirmed':
      return 'Lease Confirmed';
    case 'Cancelled':
      return 'Cancelled';
    case 'Manual Verification Required':
      return 'Manual Verification Required';
    default:
      return status;
  }
};

const getAdditionalOption = (dataType) => {
  switch (dataType) {
    case 'blank_application_id':
      return 'Blank Application ID';
    case 'Pickup Delay':
      return 'Pickup Delay';
    case 'Shipped':
      return 'Shipped';
    case 'home_delivery':
      return 'Home Delivery';
    default:
      return dataType;
  }
};

const Results = props => {
  const { className, dataReloadInit, isProgressIn, orders, ordersCount, ...rest } = props;

  const classes = useStyles();

  const { page, limit } = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const {
    fromDate,
    toDate,
    status,
    subStatus,
    queryText,
    dataType,
    leaseProvider,
    storeType,
    setFromDate,
    setToDate,
    setStatus,
    setSubStatus,
    setDataType,
    setQueryText,
    setLeaseProvider,
    setStoreType,
    cancelledSubStatus,
    setCancelledSubStatus,
    costMin,
    costMax,
    setCostMin,
    setCostMax
  } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, storeType, cancelledSubStatus, costMin, costMax]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearStatus = useCallback(() => {
    setStatus('');
    dataReloadInit();
  }, [dataReloadInit, setStatus/*, setSubStatus*/]);

  const clearSubStatus = useCallback(() => {
    setSubStatus('');
    dataReloadInit();
  }, [dataReloadInit, setSubStatus]);

  const clearDataType = useCallback(() => {
    setDataType('');
    dataReloadInit();
  }, [dataReloadInit, setDataType]);

  const clearLeaseProvider = useCallback(() => {
    setLeaseProvider('');
    dataReloadInit();
  }, [dataReloadInit, setLeaseProvider]);

  const clearStoreType = useCallback(() => {
    setStoreType('');
    dataReloadInit();
  }, [dataReloadInit, setStoreType]);

  const clearCanceledSubStatus = useCallback(() => {
    setCancelledSubStatus('');
    dataReloadInit();
  }, [dataReloadInit, setCancelledSubStatus]);

  const clearCostMin = useCallback(() => {
    setCostMin(undefined);
    dataReloadInit();
  }, [dataReloadInit, setCostMin]);

  const clearCostMax = useCallback(() => {
    setCostMax(undefined);
    dataReloadInit();
  }, [dataReloadInit, setCostMax]);


  const getNameLeaseProviderInfo = useCallback((lease_provider) => {
    // console.log('lease_provider', lease_provider);
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, []);

  const getColorLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.color || DEFAULT_LEASE_PROVIDER.color;
  }, []);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx(classes.labelWrap)}
        ref={labelWrapRef}
      >
        {queryText && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-search"
          onClick={clearSearch}
          size="small"
          variant="contained"
        > {`Search: [${queryText}] `} </Button>}
        {fromDate && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-data-from"
          onClick={clearFromDate}
          size="small"
          variant="contained"
        > {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `} </Button>}
        {toDate && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-data-to"
          onClick={clearToDate}
          size="small"
          variant="contained"
        > {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`} </Button>}
        {status && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-status"
          onClick={clearStatus}
          size="small"
          variant="contained"
        > {` Status: [${getStatusLabel(status)}]`} </Button>}
        {subStatus && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-substatus"
          onClick={clearSubStatus}
          size="small"
          variant="contained"
        > {` SubStatus: [${getSubStatusLabel(subStatus)}]`} </Button>}
        {dataType && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-data-type"
          onClick={clearDataType}
          size="small"
          variant="contained"
        > {` Additional Option: [${getAdditionalOption(dataType)}]`} </Button>}
        {leaseProvider && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-lease-provider"
          onClick={clearLeaseProvider}
          size="small"
          variant="contained"
        > {` Lease provider: [${ getNameLeaseProviderInfo(leaseProvider) }]`} </Button>}
        {storeType && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-store-type"
          onClick={clearStoreType}
          size="small"
          variant="contained"
        > {` Store type: [${storeType}]`} </Button>}
        {cancelledSubStatus && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-cancelled-substatus"
          onClick={clearCanceledSubStatus}
          size="small"
          variant="contained"
        > {` Cancelled SubStatus: [${cancelledSubStatusMasquerade[cancelledSubStatus]}]`} </Button>}
        {costMin && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-cost-min"
          onClick={clearCostMin}
          size="small"
          variant="contained"
        > {` Order price more than: [${costMin}]`} </Button>}
        {costMax && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name="btn-cost-max"
          onClick={clearCostMax}
          size="small"
          variant="contained"
        > {` Order price less than: [${costMax}]`} </Button>}
      </div>
      <Card>
        <CardHeader
          action={
            <SearchBar

              className={clsx([classes.center,classes.padding5])}
              dataReloadInit={dataReloadInit}
              isProgressIn={isProgressIn}
            />
          }
          className={classes.cardHeader}
          ref = {cardHeaderRef}
          title={
            <div className={clsx([classes.container, classes.padding5])}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
        />
        <Divider />
        <CardContent
          className={classes.content}
          style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}
        >
          <div
            className={classes.tableWrapper}
            style={{height: '100%'}}
          >
            {orders.length > 0 && !isProgressIn && <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>Date</TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                  >
                    DoradoDigital&nbsp;ID
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                  >
                    BigCommerce&nbsp;ID
                  </TableCell>
                  <TableCell className={classes.tableCell}>Customer</TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                  >
                    Cost of Goods
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                  >
                    Total
                  </TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                  <TableCell className={classes.tableCell}>SubStatus</TableCell>
                  <TableCell className={classes.tableCell}>Editing By</TableCell>
                  <TableCell className={classes.tableCell}>Lease Provider</TableCell>
                  <TableCell className={classes.tableCell}>Pickup Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.map(order => (
                  <TableRow
                    key={order.bc_id}
                    // selected={selectedOrders.indexOf(order.bc_id) !== -1}
                  >
                    <TableCell className={classes.mallIcon}>
                      <Link
                        component={RouterLink}
                        to={'/orders/' + order.id}
                      >
                        { order.store_type === 'furniture' ? <img
                          alt={'Furniture Ico'}
                          src="/images/furniture.svg"
                        /> : <img
                                                               alt={'Electronics Ico'}
                                                               src="/images/electronics.svg"
                                                                  />}
                      </Link>
                    </TableCell>
                    <TableCell className={classes.whiteSpace}>
                      {moment(order.date_created).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      <Link
                        component={RouterLink}
                        to={'/orders/' + order.id}
                      >
                        {order.id}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      <Link
                        component={RouterLink}
                        to={'/orders/' + order.id}
                      >
                        {order.bc_id}
                      </Link>
                    </TableCell>
                    <TableCell>{_.capitalize(order.billing_address.first_name)} {_.capitalize(order.billing_address.last_name)}</TableCell>
                    {/*<TableCell>{order.payment.method}</TableCell>*/}
                    <TableCell
                      align="right"
                    >
                      ${costTotal(order.products_data).toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      {/* {order.payment.currency} */}
                      ${order.total_inc_tax.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <StatusLabel status={order.bot_status} />
                    </TableCell>
                    <TableCell className={classes.substatuses}>
                      <div className={classes.tags}>
                        {order.bot_custom_status?.map(warning =>
                          <SubstatusLabel
                            key={warning}
                            substatus={warning}
                          />
                        )}
                        {(!order.bot_substatus) || <SubstatusLabel substatus={order.bot_substatus} />}

                      </div>
                    </TableCell>
                    <TableCell>
                      {(!_.isEmpty(order.edit) && !_.isEmpty(order.edit.owner)) &&
                      <div className={classes.editorContainer}>
                        <UserAvatar
                          className={classes.history_avatar}
                          is_avatar={order.edit.owner.is_avatar}
                          uid={order.edit.owner.uid}
                        />
                        <div>
                          <div name="bot_status_full_name">{_.capitalize(order.edit.owner.first_name) + ' ' + _.capitalize(order.edit.owner.last_name)}</div>
                        </div>
                      </div>}
                      {/*{(!_.isEmpty(order.edit) && !_.isEmpty(order.edit.owner)) && (_.capitalize(order.edit.owner.first_name)  + ' ' + _.capitalize(order.edit.owner.last_name))}*/}
                    </TableCell>
                    <TableCell>
                      <Label
                        className={classes.fulfillment_tag}
                        color={getColorLeaseProviderInfo(order?.lease_provider === 'uown' && order?.store_type === 'furniture' ? 'uown_furniture' : order?.lease_provider)}
                        variant="contained"
                      >
                        {getNameLeaseProviderInfo(order?.lease_provider === 'uown' && order?.store_type === 'furniture' ? 'uown_furniture' : order?.lease_provider)}
                      </Label>
                    </TableCell>
                    <TableCell>
                      {maxDate(order.products_data)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>}
            {isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            className={classes.wrap}
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {ordersCount} Orders found
            {ordersCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(ordersCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={ordersCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired,
  isProgressIn: PropTypes.bool.isRequired
};

Results.defaultProps = {
  orders: [],
  dataReloadInit: PropTypes.func.isRequired,
  isProgressIn: false
};

export default Results;
