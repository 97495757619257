import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import useForm, { FormContext } from 'react-hook-form';
import {
  ServicesStatus,
  ApprovalAmount,
  LedgerSummaryTotals,
  ApprovalUsage,
  ReadyForPickup,
  RevenueByDay,
  RevenueByMonth,
  Header,
  RevenuePerStart,
  AveragePerStart
} from './components';

import { axios, toastHttpError, useGetMarketingTree } from 'utils';
import { useDispatch } from 'react-redux';
import { DashboardFilter, Page } from 'components';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  child: {
    paddingTop: theme.spacing(2)
  },
  dashboardFilterWrap: {
    padding: '0 !important'
  }
}));

const Dashboard = () => {
  console.log('Version:  v6.00.053');
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUserPermissions = JSON.parse(localStorage.getItem('currentUserPermissions')) || [];

  const marketingTreeData = useGetMarketingTree();
  const marketingTreeList = marketingTreeData.marketingList;

  const [contactsCount, setContactsCount] = useState([]);
  const [employeesCount, setEmployeesCount] = useState([]);
  const [servicesCount, setServicesCount] = useState([]);

  const [repeatCustomers, setRepeatCustomers] = useState([]);
  const page = 0;
  const limit = 5;

  const methods = useForm({
    defaultValues: {
      oid: null,
      pid: null,
      sid: null,
      fromDateTotalApprovalAmount: null,
      toDateTotalApprovalAmount: null,
      fromDateRevenueByDayAmount: null,
      toDateRevenueByDayAmount: null,
      fromDateRevenueByMonthAmount: null,
      toDateRevenueByMonthAmount: null,
      fromDateRevenuePerStart: null,
      toDateRevenuePerStart: null,
      fromDateAverageRevenuePerStart: null,
      toDateAverageRevenuePerStart: null,
      fromDateLedgerSummaryReport: null,
      toDateLedgerSummaryReport: null
    }
  });
  const { setValue } = methods;

  useEffect(() => {
    const { oid, pid, sid } = JSON.parse(localStorage.getItem('currentDashboardFilters')) || {};

    setValue('oid', oid);
    setValue('pid', pid);
    setValue('sid', sid);

    const { fromDateTotalApprovalAmount, toDateTotalApprovalAmount } =
      JSON.parse(localStorage.getItem('dashboardApprovalAmountFilters')) || {};

    const { fromDateRevenueByDayAmount, toDateRevenueByDayAmount } =
      JSON.parse(localStorage.getItem('dashboardRevenueByDayAmountFilters')) || {};

    const { fromDateRevenueByMonthAmount, toDateRevenueByMonthAmount } =
      JSON.parse(localStorage.getItem('dashboardRevenueByMonthAmountFilters')) || {};

    const { fromDateApprovalUsage, toDateApprovalUsage } =
      JSON.parse(localStorage.getItem('dashboardApprovalUsageFilters')) || {};

    const { fromDateRevenuePerStart, toDateRevenuePerStart } =
      JSON.parse(localStorage.getItem('dashboardRevenuePerStartFilters')) || {};

    const { fromDateAverageRevenuePerStart, toDateAverageRevenuePerStart } =
      JSON.parse(localStorage.getItem('dashboardAverageRevenuePerStartFilters')) || {};

    const { fromDateLedgerSummaryReport, toDateLedgerSummaryReport } =
      JSON.parse(localStorage.getItem('dashboardLedgerSummaryReportFilters')) || {};

    setValue('fromDateTotalApprovalAmount', fromDateTotalApprovalAmount || null, true);
    setValue('toDateTotalApprovalAmount', toDateTotalApprovalAmount || null, true);

    setValue('fromDateRevenueByDayAmount', fromDateRevenueByDayAmount || null, true);
    setValue('toDateRevenueByDayAmount', toDateRevenueByDayAmount || null, true);

    setValue('fromDateRevenueByMonthAmount', fromDateRevenueByMonthAmount || null, true);
    setValue('toDateRevenueByMonthAmount', toDateRevenueByMonthAmount || null, true);

    setValue('fromDateApprovalUsage', fromDateApprovalUsage || null, true);
    setValue('toDateApprovalUsage', toDateApprovalUsage || null, true);

    setValue('fromDateRevenuePerStart', fromDateRevenuePerStart || null, true);
    setValue('toDateRevenuePerStart', toDateRevenuePerStart || null, true);

    setValue('fromDateAverageRevenuePerStart', fromDateAverageRevenuePerStart || null, true);
    setValue('toDateAverageRevenuePerStart', toDateAverageRevenuePerStart || null, true);

    setValue('fromDateLedgerSummaryReport', fromDateLedgerSummaryReport || null, true);
    setValue('toDateLedgerSummaryReport', toDateLedgerSummaryReport || null, true);

  }, [setValue]);

  useEffect(() => {
    const payload = {
      main_title: '',
      secondary_title: 'Dashboard',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    const fetchContacts = () => {
      axios().get('/customers', {
        params: {
          page,
          limit
        }
      }).then(response => {
        if (mounted) {
          setContactsCount(response?.data?.count || 0);
        }
      }).catch(response => {
        toastHttpError(response);
      });
    };

    const fetchRepeatCustomers = () => {
      axios().post('/dashboard_data', {
        // params: {
        data_type: ['repeat_customers_count']
        // }
      }).then(response => {
        if (mounted) {
          setRepeatCustomers(response?.data?.data[0]?.value || 0);
        }
      }).catch(response => {
        toastHttpError(response);
      });
    };

    const fetchEmployees = () => {
      axios().get('/employees', {
        params: {
          page,
          limit,
          type: 'Person'
        }
      }).then(response => {
        if (mounted) {
          setEmployeesCount(response?.data?.count || 0);
        }
      }).catch(response => {
        toastHttpError(response);
      });

      axios().get('/employees', {
        params: {
          page: 0,
          limit: 1,
          type: 'Service'
        }
      }).then(response => {
        if (mounted) {
          setServicesCount(response?.data?.count || 0);
        }
      }).catch(response => {
        toastHttpError(response);
      });
    };

    if (currentUserPermissions.includes('manage customer')) fetchContacts();
    if (currentUserPermissions.includes('manage employee')) fetchEmployees();
    fetchRepeatCustomers();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Dashboard"
      >
        <div>
          <Grid
            alignItems="center"
            container
            direction="column"
            justify="space-between"
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <Header
                  repeatCustomers={repeatCustomers}
                  totalContacts={contactsCount}
                  totalEmployees={employeesCount}
                  totalServices={servicesCount}
                />
              </Grid>
            </Grid>

            <Grid
              className={classes.child}
              container
              spacing={3}
            >
              <Grid
                className={classes.dashboardFilterWrap}
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <DashboardFilter
                  marketingTreeList={marketingTreeList}
                />
              </Grid>
            </Grid>

            {(currentUserPermissions.includes('read approval usage dashboard')) &&
            <Grid
              className={classes.child}
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <ApprovalUsage marketingTreeList={marketingTreeList} />
              </Grid>
            </Grid>
            }

            {(currentUserPermissions.includes('dashboard')) &&
            <Grid
              className={classes.child}
              container
              spacing={3}
            >
              {(currentUserPermissions.includes('read ledger summary totals dashboard')) &&
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <LedgerSummaryTotals marketingTreeList = {marketingTreeList}/>
              </Grid>
              }
              {(currentUserPermissions.includes('read orders cash dashboard')) &&
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <ReadyForPickup />
              </Grid>
              }
              {(currentUserPermissions.includes('read approval amount dashboard')) &&
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <ApprovalAmount marketingTreeList={marketingTreeList} />
              </Grid>
              }
            </Grid>}

            {(currentUserPermissions.includes('read revenue dashboard')) &&
            <Grid
              className={classes.child}
              container
              spacing={3}
            >
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <RevenueByDay marketingTreeList={marketingTreeList} />
              </Grid>
              <Grid
                item
                lg={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <RevenueByMonth marketingTreeList={marketingTreeList}/>
              </Grid>
            </Grid>}

            {(currentUserPermissions.includes('read revenue per start dashboard')) &&
            <Grid
              className={classes.child}
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <RevenuePerStart marketingTreeList={marketingTreeList} />
              </Grid>
            </Grid>}

            {(currentUserPermissions.includes('read service state')) &&

            <Grid
              className={classes.child}
              container
              spacing={3}
            >
              {(currentUserPermissions.includes('read revenue per start dashboard')) &&
              <Grid
                item
                lg={6}
                sm={6}
                xl={3}
                xs={12}
              >
                <AveragePerStart  marketingTreeList={marketingTreeList}/>
              </Grid>
              }

              <Grid
                item
                lg={6}
                sm={6}
                xl={3}
                xs={12}
              >
                <ServicesStatus />
              </Grid>
            </Grid>}
          </Grid>
        </div>
      </Page>
    </FormContext>
  );
};

export default Dashboard;
