import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';
import moment from 'moment/moment';
import { STORE_TYPE_OPTIONS } from '../../../../common';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
}));

const leaseProviderOptions = [
  // {
  //   value: 'acima',
  //   title: 'Acima'
  // },
  // {
  //   value: 'westcreek',
  //   title: 'Koalafi'
  // },
  // {
  //   value: 'aff',
  //   title: 'American First Finance'
  // },
  // {
  //   value: 'flexshopper',
  //   title: 'FlexShopper'
  // },
  // {
  //   value: 'paytomorrow',
  //   title: 'PayTomorrow'
  // },
  {
    value: 'uown',
    title: 'UOwn'
  },
  {
    value: 'katapult',
    title: 'Katapult'
  },
];

export const Filter = props => {
  const { open, onClose, marketingTreeList, className } = props;

  const classes = useStyles();

  const { register, watch, setValue, handleSubmit } = useFormContext();
  const { setMultiFields } = useFiltersSearch();

  const clearValues = useCallback(() => {
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('oid', '', true);
    setValue('pid', '', true);
    setValue('sid', '', true);
    setValue('eid', '', true);
    setValue('storeType', '', true);
    setValue('leaseProvider', '', true);

  }, [setValue]);

  register({ name: 'oid', type: 'custom' });
  register({ name: 'pid', type: 'custom' });
  register({ name: 'sid', type: 'custom' });
  register({ name: 'eid', type: 'custom' });
  register({ name: 'storeType', type: 'custom' });
  register({ name: 'leaseProvider', type: 'custom' });

  const {fromDate, toDate, oid, pid, sid, eid, storeType, leaseProvider} = watch();

  const [pidList, setPidList] = useState([]);
  const [sidList, setSidList] = useState([]);
  const [eidList, setEidList] = useState([]);

  const onHandleChange = useCallback((value, change) => {
    switch (change) {
      case 'oid':
        setValue('oid', value, true);
        setValue('pid', '', true);
        setValue('sid', '', true);
        setValue('eid', '', true);
        break
      case 'pid':
        setValue('pid', value, true);
        setValue('sid', '', true);
        setValue('eid', '', true);
        break
      case 'sid':
        setValue('sid', value, true);
        setValue('eid', '', true);
        break
      case 'eid':
        setValue('eid', value, true)
        break
      default:
        break
    }
  }, [setValue])

  useEffect(function() {
    if (!!fromDate && !toDate) {
      setValue('toDate', moment());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(function() {
    if (oid) setPidList(marketingTreeList.find((element) => element.oid === +oid)?.pid_list?.map((elem => ({name: elem.name, value: elem.pid}))));
  }, [oid,marketingTreeList, setPidList]);

  useEffect(function() {
    if (pid) setSidList(marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.map((elem) => ({name: elem.name, value: elem.sid})));
  }, [oid, pid, marketingTreeList, setSidList]);

  useEffect(function() {
    if (sid) setEidList(marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.find((elem) => elem.sid === +sid)?.eid_list.map((elem) => ({name: elem.name, value: elem.eid})));
  }, [oid, pid, sid, marketingTreeList, setEidList]);

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ oid, pid, sid, eid, fromDate, toDate, storeType, leaseProvider}) => {
          setMultiFields({
            'from': fromDate,
            'to':  toDate,
            'oid': oid,
            'pid': pid,
            'sid': sid,
            'eid': eid,
            'store_type': storeType,
            'lease_provider': leaseProvider
          });
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Offer ID"
                  name="oid"
                  onChange={({currentTarget}) => onHandleChange(currentTarget.value, 'oid')}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={oid}
                  variant="outlined"
                >
                  <option value="" />
                  {marketingTreeList?.map((elem) => ({name: elem.name, value: elem.oid}))?.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                    > 
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  disabled={oid===undefined || oid===''}
                  fullWidth
                  label="Program ID"
                  name="pid"
                  onChange={({currentTarget}) => onHandleChange(currentTarget.value, 'pid')}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={pid}
                  variant="outlined"
                >
                  <option value="" />
                  {oid && pidList?.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  disabled={pid===undefined || pid===''}
                  fullWidth
                  label="Source ID"
                  name="sid"
                  onChange={({currentTarget}) => onHandleChange(currentTarget.value, 'sid')}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={sid}
                  variant="outlined"
                >
                  <option value="" />
                  {pid && sidList?.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  disabled={sid===undefined || sid===''}
                  fullWidth
                  label="Extra ID"
                  name="eid"
                  onChange={({currentTarget}) => onHandleChange(currentTarget.value, 'eid')}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={eid}
                  variant="outlined"
                >
                  <option value="" />
                  {sid && eidList?.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDate'}
                  label={'From Date* '}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDate'}
                  label={'To Date* '}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Store Type"
                  name="storeType"
                  onChange={({ currentTarget }) => setValue('storeType', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={storeType}
                  variant="outlined"
                >
                  <option value="" />
                  {
                    STORE_TYPE_OPTIONS.map(storeType => (
                      <option value={storeType.value}>{storeType.label}</option>
                    ))
                  }
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Lease Provider"
                  name="leaseProvider"
                  onChange={({ currentTarget }) => setValue('leaseProvider', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={leaseProvider}
                  variant="outlined"
                >
                  <option value="" />
                  {
                    leaseProviderOptions.map(leaseProvider => (
                      <option value={leaseProvider.value}>{leaseProvider.title}</option>
                    ))
                  }
                </TextField>
              </div>
            </div>

          </div>
          {/*<div>*/}
          {/*  {JSON.stringify(watch())}*/}
          {/*</div>*/}
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func.isRequired,
  marketingTreeList: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
